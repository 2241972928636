import React, { useEffect, useState } from "react";
import Card from './Component/Card';
import Toggle from 'react-toggle';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import "react-toggle/style.css";
import moment from "moment";
import Notiflix from 'notiflix';
export default function UserAccount() {
    let token = localStorage.getItem('token');

    const [data, setData] = useState([]);
    const [number, setNumber] = useState('');
    const [count, setCount] = useState('');
    const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });


    const fetchPaymentsData = async () => {
        Notiflix.Loading.circle()
        try {
            let apiUrl = `${baseUrl}/user/get-payments-data?mobile=${number}`;
            apiUrl += `&page=${pagination.page}&limit=${pagination.pageSize}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.data, "useraccount")
            const modifiedData = response.data.data.rows.map((item, i) => ({
                ...item,
                mobile: item.userDetail.mobile,
                userName: item.userDetail.username,
                id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,
                subscription_id: item.subscription_id,
                active: item.active, // Use active instead of status
            }));
            setData(modifiedData);

            setCount(response.data.data.count);
            Notiflix.Loading.remove();
        } catch (error) {
            console.error("Failed to fetch payments data:", error);
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error.message);
        }
    };

    useEffect(() => {
        // fetchPaymentsData();

        const interval = setTimeout(fetchPaymentsData,10)
        return ()=>clearTimeout(interval);
    }, [number, pagination]);

    const updateStatus = async (id, currentActive) => {
        Notiflix.Loading.circle()
        try {
            const newActive = currentActive === "1" ? "0" : "1"; // Compare as string
            const subscriptionId = data.find(item => item.id === id).subscription_id;
            const accountNumber = data.find(item => item.id === id).account_number;

            console.log({ subscriptionId, accountNumber })

            await axios.patch(
                `${baseUrl}/user/update-bank-active-status`,
                { update_active: newActive, subscription_id: subscriptionId, account_number: accountNumber },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setData(prevData => prevData.map(item =>
                item.id === id ? { ...item, active: newActive } : item
            ));
            Notiflix.Loading.remove();
            console.log(`Updated subscription_id: ${subscriptionId}`);
        } catch (error) {
            console.error("Failed to update status:", error);
            Notiflix.Loading.remove();
        }

    };

    const columns = [
        { field: 'id', headerName: 'SN', width: 80, align: 'center', headerAlign: 'center' },
        { field: 'userName', headerName: 'User Name', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'mobile', headerName: 'Mobile', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'bank_name', headerName: 'Bank Name', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'account_number', headerName: 'Account Number', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'account_name', headerName: 'Account Name', flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: 'active',
            headerName: 'Status',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Toggle
                    checked={params.row.active === "1"}
                    icons={{
                        checked: <span className="custom-icon"></span>,
                        unchecked: <span className="custom-icon"></span>,
                    }}
                    onChange={() => updateStatus(params.row.id, params.row.active)}
                />
            ),
        },
    ];

    const handleDownload = async () => {
        try {
            let apiUrl = `${baseUrl}/user/export-file-data/user-account?`;

            apiUrl += `&mobile=${number}`;


            const response = await axios.get(apiUrl, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `User Account ${moment().format('DD-MM-YYYY')} .csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error, "API does not work");
            Notiflix.Notify.failure(error.message);
        }
    }

    function CustomToolbar() {
        return (
            <button onClick={handleDownload} className='btn btn-dark'>Export</button>
        );
    }

    return (
        <div className='myContainer'>
            <div className='innerContainer'>
                <div className='containerCard'>
                    <div className="col-md-3">
                        <Card
                            title={"User Accounts"}
                            number={count ? count : 0}
                            className='myCard indiviusalCard'
                        />
                    </div>
                </div>

                <div className='row justify-content-center' >
                    <div className='filter d-flex justify-content-between flex-wrap' style={{width:'80%'}}>
                        <div className='filtersbymobile'>
                            <div className='title'>
                                <h6>Filter By Phone Number:</h6>
                            </div>
                            <div className='mobilePicker'>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="mobile number"
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className='title'>
                                <h6>Filter By Page No:</h6>
                            </div>
                            <div>
                                <div>
                                    <input type="text" className="form-control" placeholder="Page Number"
                                        onChange={(e) => {
                                            const page = e.target.value ? parseInt(e.target.value, 10) : 1;
                                            setPagination((prev) => ({ ...prev, page }));
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="datagrid containerDataGrid">
                    <h5>Users Account</h5>
                    <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                paginationModel={{
                                    page: pagination.page - 1,
                                    pageSize: pagination.pageSize,
                                  }}
                                pagination
                                paginationMode='server'
                                pageSize={10}
                                rowCount={count}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                onPaginationModelChange={(e, f) => { console.log(e); setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}
