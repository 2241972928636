import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseUrl } from './Constant';
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import Notiflix from 'notiflix';
import { DataGridComponent } from "./Component/DataGrid/SkippedGrid";

export default function AddSchemeList() {


    const [file, setFile] = useState(null);
    const [updatedFile, setUpdatedFile] = useState(null)
    const [msg, setMsg] = useState('')
    const fileInputRef = useRef(null);
    const [data, setData] = useState('')
    const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });
    const [value, setValue] = React.useState('1');
    const [tcount, setTcount] = useState('');
    const [skipped, setSkipped] = useState(false);
    const [skippedRows, setSkippedRow] = useState(0);
    const [sliceCount, setSliceCount] = useState(0);

    const [apiFilter, setApiFilter] = useState({})
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
        setMsg("")
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!file) {
            console.log("No file selected.");
            setMsg("No file selected")
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        Notiflix.Loading.circle();

        axios.post(`${baseUrl}/user/upload-excel?`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                toast.success(response.data.message)
                fileInputRef.current.value = '';
                Notiflix.Loading.remove();
                getCsv()

            })
            .catch((error) => {
                fileInputRef.current.value = '';
                Notiflix.Loading.remove();
                toast.error(error?.response?.data?.message || error?.message)

            });
    };



    const handleUpdateFileSubmit = (event) => {
        event.preventDefault();

        if (!updatedFile) {
            console.log("No file selected.");
            setMsg("No file selected")
            return;
        }

        const formData = new FormData();
        formData.append("file", updatedFile);

        Notiflix.Loading.circle();

        axios.put(`${baseUrl}/user/update-csv-file-data?`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                Notiflix.Loading.remove();
                toast.success(response.data.message)
                fileInputRef.current.value = '';

                getCsv()
            })
            .catch((error) => {
                Notiflix.Loading.remove();
                fileInputRef.current.value = '';
                toast.error(error?.response?.data?.message || error?.message)
            });


    };

    const handleUpdateFileUpload = (event) => {
        setUpdatedFile(event.target.files[0]);
        setMsg("")
    };
    const handleDownload = async () => {
        try {

            Notiflix.Loading.circle();
            let apiUrl = `${baseUrl}/user/export-file-data/add-scheme-list?`;

            const response = await axios.get(apiUrl, { responseType: 'blob' });
            Notiflix.Loading.remove();
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Scheme list data  ${moment().format('DD-MM-YYYY')} .csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            Notiflix.Loading.remove();
            console.log(error, "API does not work");
        }
    }

    function CustomToolbar() {
        return (
            <button onClick={handleDownload} className='btn btn-dark'>Export</button>
        );
    }

    const getCsv = async () => {
        Notiflix.Loading.circle();
        try {
            // Create URLSearchParams instance to handle query params
            const params = new URLSearchParams({
                page: pagination.page,
                limit: pagination.pageSize,
                ...apiFilter // Spread the apiFilter object to include its key-value pairs
            });

            const response = await axios.get(`${baseUrl}/user/get-csv-file-data?${params.toString()}`);
            console.log(response, "fetch csv data");

            const modifiedData = response.data.data.rows.map((item, i) => {
                return {
                    ...item,
                    id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,
                    created: item.created_at.split("T")[0]
                };
            });

            setData(modifiedData.slice(sliceCount));
            setTcount(response.data.data.count);

            Notiflix.Loading.remove();
        } catch (error) {
            console.error('Error fetching CSV file:', error);
            Notiflix.Loading.remove();
        }
    };

    useEffect(() => {
        getCsv();
    }, [pagination])

    const columns = [
        {
            field: 'id',
            headerName: 'SN',
            width: '50',
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'win_spinCount',
            headerName: 'Spin Number',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'prize_id',
            headerName: 'Prize Id',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'spin_status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },

    ];

    useEffect(() => {
        getCsv();
    }, [apiFilter])


    const skipToNotPlayed = async () => {

        // try {

        //     if (skipped) {
        //         setSkipped(false);
        //         setPagination({ ...pagination, page: 0 });
        //         setSkippedRow(0);
        //         // setSliceCount(0);
        //         return;
        //     }

        //     Notiflix.Loading.circle();

        //     const response = await axios.get(`${baseUrl}/user/get-csv-file-data/skip_number`, {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('token')}`
        //         }
        //     });

        //     if (response.data?.data?.skip_number) {
        //         setPagination({ ...pagination, page: parseInt(Math.floor(parseInt(response.data.data.skip_number / (pagination.pageSize))).toFixed(0)) + 1 })
        //         setSkippedRow(response.data.data?.skip_number % pagination.pageSize);
        //         // setSliceCount(sliceCount);
        //         setSkipped(true);
        //         console.log(`skipped_number`, response.data.data?.skip_number % pagination.pageSize);
        //     }

        //     Notiflix.Loading.remove();

        //     return response;

        // } catch (err) {
        //     Notiflix.Loading.remove();
        //     console.log(err);
        // }

        setPagination({ page: 1, pageSize: 10 });

        if (skipped) {
            setApiFilter({});
            // getCsv();
            setSkipped(false);
            return;
        }
        setApiFilter({ spin_status: '0' });
        setSkipped(true);
    };


    return (
        <div className='addmyScheme_container'>
            <Toaster />
            <div className='addmyScheme'>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Update Current Scheme List" value="1" />
                            <Tab label="Add Scheme List" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div>

                            <Form onSubmit={handleUpdateFileSubmit}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='label'>*Please upload a updated csv file.</Form.Label>
                                    <Form.Control type="file" onChange={handleUpdateFileUpload} ref={fileInputRef} accept=".csv" />
                                </Form.Group>
                                <p className='text-center m-0'>{msg}</p>
                                <Button type="submit" variant="primary">Upload Edit Csv</Button>
                            </Form>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <div>

                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='label'>*Please upload a csv file.</Form.Label>
                                    <Form.Control type="file" onChange={handleFileUpload} ref={fileInputRef} accept=".csv" />
                                </Form.Group>
                                <p className='text-center m-0'>{msg}</p>
                                <Button type="submit" variant="primary">Upload Csv</Button>
                            </Form>
                        </div>
                    </TabPanel>
                </TabContext>

            </div>

            <div className="clear-sms btn-wrapper mt-5">
                <button onClick={skipToNotPlayed}>
                    {!skipped ? "Skip To Not-Played" : "View All"}
                </button>
                <div className='mt-3'>
                            <div className='title'>
                                <h6>Filter By Page No:</h6>
                            </div>
                            <div>
                                <div>
                                    <input type="text" className="form-control" placeholder="Page Number"
                                        onChange={(e) => {
                                            const page = e.target.value ? parseInt(e.target.value, 10) : 1;
                                            setPagination((prev) => ({ ...prev, page }));
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
            </div>

            <div className='viewCsv mt-5'>
                <div className="datagrid  containerDataGrid w-100">
                    <h5>Scheme List</h5>

                    {!skipped && <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                paginationModel={{
                                  page: pagination.page - 1, 
                                  pageSize: pagination.pageSize,
                                }}
                                
                                paginationMode='server'
                                pageSize={pagination.pageSize}
                                rowCount={tcount}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                onPaginationModelChange={(e, f) => {setPagination({...pagination,page: e.page + 1, pageSize: e.pageSize }) }}
                                slots={{
                                  toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    </div>}

                    {skipped && <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGridComponent
                                rows={data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: pagination.pageSize,
                                            page: pagination.page - 1
                                        },
                                    },
                                }}
                                pagination
                                paginationMode='server'
                                pageSize={pagination.pageSize}
                                page={pagination.page}
                                rowCount={tcount}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                onPaginationModelChange={(e, f) => { setSkippedRow(0); setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    </div>}
                </div>


            </div>
        </div>
    );
}
