import Card from './Component/Card';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import moment from 'moment';

export default function SpinsAvaible() {
  let token = localStorage.getItem('token');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState('')
  const [number, setNumber] = useState('')
  const [network, setNetwork] = useState('')
  const [avialableSpin, setAvialableSpin] = useState('')
  const [spinPurchased, setSpinPurchased] = useState('')
  const [spinUsed, setSpinUsed] = useState('')
  const [expiredSpins, setExpiredSpins] = useState('')
  // Convert the string to a Date object
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });
  const [tcount, setTcount] = useState('')

  let startYear = startDateObj.getFullYear();
  let startMonth = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
  let startDates = ('0' + startDateObj.getDate()).slice(-2);

  let endYear = endDateObj.getFullYear();
  let endMonth = ('0' + (endDateObj.getMonth() + 1)).slice(-2);
  let endDates = ('0' + endDateObj.getDate()).slice(-2);

  let startFormattedDate = `${startYear}-${startMonth}-${startDates}`;
  let endFormattedDate = `${endYear}-${endMonth}-${endDates}`;

  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  }

  const handleDownload = async () => {
    try {
      let apiUrl = `${baseUrl}/user/export-file-data/available-spins?`;

      if (startDate && endDate) {
        apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
      }

      if (network !== 'all') {
        apiUrl += `&network=${network}`;
      }

      const response = await axios.get(apiUrl, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Available Spin ${moment().format('DD-MM-YYYY')} .csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.log(error, "API does not work");
    }
  }

  function CustomToolbar() {
    return (
      <button onClick={handleDownload} className='btn btn-dark'>Export</button>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      Notiflix.Loading.circle()
      try {
        let apiUrl = `${baseUrl}/user/available-spins?`;

        if (startDate && endDate) {
          apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
        }


        apiUrl += `&mobile=${number}`;

        if (network !== 'all') {
          apiUrl += `&network=${network}`;
        }
        apiUrl += `&page=${pagination.page}&limit=${pagination.pageSize}`;

        const response = await axios.get(
          apiUrl,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.rows, "availabe spin")
        const modifiedData = response.data.data.userData.map((item, i) => {
          return {
            ...item,
            id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,

          }
        });
        const avialableSpin = response.data.data.spinsData.available_spins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        const spinPurchased = response.data.data.spinsData.total_spins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        const spinUsed = response.data.data.spinsData.redeemed_spins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        const expiredSpins = response.data.data.spinsData.expired_spins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setAvialableSpin(avialableSpin)
        setSpinPurchased(spinPurchased)
        setSpinUsed(spinUsed)
        setExpiredSpins(expiredSpins)
        setTcount(response.data.rows)

        setData(modifiedData);
        Notiflix.Loading.remove();
      } catch (error) {
        console.log(error, "api does not work");
        Notiflix.Loading.remove();
      }
    };
    const interval = setTimeout(fetchData,10)
    return ()=>clearTimeout(interval);
  }, [startDate, endDate, number, network, pagination]);


  const columns = [
    {
      field: 'id',
      headerName: 'SN',
      width: '80',
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'mobile',
      headerName: 'Mobile Number',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'


    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'total_spins',
      headerName: 'Spin Purchased',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },

    {
      field: 'redeemed_spins',
      headerName: 'Spin Used',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },

    {
      field: 'available_spins',
      headerName: 'Balance Spin',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'expired_spins',
      headerName: 'Expired Spin',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },



  ];

  return (
    <div className='myContainer'>
      <div className='innerContainer'>
        <div className='containerCard'>

          <div className="col-md-3">
            <Card
              title={"Spin Purchased"}
              number={spinPurchased ? spinPurchased : '0'}
              className='myCard indiviusalCard'
            />
          </div>
          <div className="col-md-3">
            <Card
              title={"Spin Used"}
              number={spinUsed ? spinUsed : '0'}
              className='myCard indiviusalCard'
            />
          </div>

          <div className="col-md-3 ">
            <Card
              title={"Available Spins"}
              number={avialableSpin ? avialableSpin : '0'}
              className='myCard indiviusalCard'
            />
          </div>

          <div className="col-md-3 ">
            <Card
              title={"Expired Spins"}
              number={expiredSpins ? expiredSpins : '0'}
              className='myCard indiviusalCard'
            />
          </div>

        </div>

        <div className='row'>
          <div className='filter'>
            <div className='filterByDate date_number'>
              <div>
                <div className='title'>
                  <h6>
                    Filter By Date:
                  </h6>
                </div>
                <div className='datePicker'>
                  <div>
                    <p className='m-0'>Start Date</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                    />
                  </div>
                  <div>
                    <p className='m-0'>End Date</p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column justify-content-between'>
                <div className='title'>
                  <h6>
                    Filter By Phone Number:
                  </h6>
                </div>
                <div className='mobilePicker'>

                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="mobile number" aria-label="Username" onChange={(e) => setNumber(e.target.value)} />

                  </div>
                </div>
              </div>
            </div>
            <div className='filterByNetwork py-3 d-flex justify-content-between flex-wrap'>
              <div>
                <div className='title'>
                  <h6>Filter By Network:</h6>
                </div>
                <div className='networkPicker'>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="allNetwork" value="all" onChange={handleNetworkChange} checked={network === 'all'} />
                    <label className="form-check-label" htmlFor="allNetwork">All</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="mtnNetwork" value="mtn" onChange={handleNetworkChange} checked={network === 'mtn'} />
                    <label className="form-check-label" htmlFor="mtnNetwork">MTN</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="airtelNetwork" value="airtel" onChange={handleNetworkChange} checked={network === 'airtel'} />
                    <label className="form-check-label" htmlFor="airtelNetwork">Airtel</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="gloNetwork" value="glo" onChange={handleNetworkChange} checked={network === 'glo'} />
                    <label className="form-check-label" htmlFor="gloNetwork">Glo</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="etisalatNetwork" value="9mobile" onChange={handleNetworkChange} checked={network === '9mobile'} />
                    <label className="form-check-label" htmlFor="etisalatNetwork">9mobile</label>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='title'>
                  <h6>Filter By Page No:</h6>
                </div>
                <div>
                  <div>
                  <input type="text" className="form-control" placeholder="Page Number"
                   onChange={(e) => {
                    const page = e.target.value ? parseInt(e.target.value, 10) : 1;
                    setPagination((prev) => ({ ...prev, page }));
                  }}
                  />
                  
                  </div>
                </div>
              </div>
              
            </div>

          </div>


        </div>

        <div className="datagrid containerDataGrid">
          <h5>Available Spins</h5>

          <div className="datagridBox">
            <Box sx={{ height: '500px', width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                paginationModel={{
                  page: pagination.page - 1,
                  pageSize: pagination.pageSize,
                }}

                paginationMode='server'
                pageSize={pagination.pageSize}
                rowCount={tcount}
                pageSizeOptions={[10]}
                rowsPerPageOptions={[10]}
                onPaginationModelChange={(e, f) => { setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                slots={{
                  toolbar: CustomToolbar,
                }}

              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}







