

const lib = {
    convertToLagosTime: (value) => {
        try {
            let date = new Date(value);
            date = date.getTime();

            // return date of 1 hour ahead
            // return new Date(date - (4.5) * 60 * 60 * 1000);
            return new Date(date);
        } catch (err) {
            return "";
        }
    }
}

module.exports = { lib };